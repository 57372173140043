import React from "react";
import { Tooltip } from "antd";

interface CopyableTextProps {
    text: string;
    maxWidth?: string;
    tooltipTitle?: string;
}

export const CopyableText: React.FC<CopyableTextProps> = ({
    text,
    maxWidth = "300px",
    tooltipTitle,
}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
            }}
        >
            <Tooltip
                title={text ? tooltipTitle || text : "No content available"}
            >
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth,
                    }}
                >
                    {text || "No content to display"}
                </div>
            </Tooltip>
            <Tooltip title="Copy to clipboard">
                <button
                    onClick={() => {
                        navigator.clipboard.writeText(text);
                    }}
                    style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        color: "#1890ff",
                    }}
                >
                    📋
                </button>
            </Tooltip>
        </div>
    );
};
