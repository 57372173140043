import axios from "axios";
import { AuthCookies, RemoveSessionLogin } from "utils";
import { EndpointAuth } from "./constant";
import { notification } from "antd";

let baseURL = "";

if (process.env.NODE_ENV === 'development') {
    baseURL = process.env.REACT_APP_LOCAL_BASE_URL || '';
}

// Create a base axios instance with common headers
export const api = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

// Handle errors for the base axios instance
api.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        return Promise.reject(
            error.response.data?.message
                ? error.response.data?.message.toString()
                : error.response.data.toString()
        );
    }
);

// Create a private axios instance with a bearer token
export const apiPrivate = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

// Attach the bearer token to requests
apiPrivate.interceptors.request.use(
    (config) => {
        if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${localStorage.getItem(
                "accessToken"
            )}`;
        }
        return config;
    },
    (error) => {
        notification.error({
            message: error ?? "system error occurred",
        });
        Promise.reject(error);
    }
);
// Handle 401 errors by refreshing the token
apiPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response } = error;
        let errorMessage = response.data.message
            ? response.data.message.toString()
            : response.data.toString();
        const toastMsg = () => {
            notification.error({
                message: errorMessage ?? "system error occurred",
            });
        };

        const prevRequest = error?.config;
        if (prevRequest?.sent) {
            toastMsg();
        }

        if (error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return apiPrivate(prevRequest);
        }
        toastMsg();
        return Promise.reject(response.data.errorResponse || errorMessage);
    }
);

// Refresh the bearer token
const refresh = async () => {
    try {
        const { data } = await api.post(EndpointAuth.refreshToken, {
            token: AuthCookies?.refreshToken,
        });
        AuthCookies.value = {
            ...AuthCookies.value,
        };
        localStorage.setItem("accessToken", data.accessToken.toString());
        return data.accessToken;
    } catch (e) {
        RemoveSessionLogin();
    }
};
