import React from "react";

interface FormattedTimeProps {
    time: string;
}

export const FormattedTime: React.FC<FormattedTimeProps> = ({ time }) => {
    if (!time) {
        return <span>Empty</span>
    }
    const formattedTime = new Date(time).toLocaleString();

    return <span>{formattedTime}</span>;
};
