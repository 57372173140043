export enum EndpointAuth {
    refreshToken = "/v1/auth/refresh",
    register = "/v1/auth/register",
    login = "/v1/auth/login",
    emailVerification = "/v1/auth/resend-email-verification",
    emailHandleVerification = "/v1/auth/verify-email",
    forgotPassword = "/v1/auth/forgot-password",
    handleResetPassword = "/v1/auth/reset-password",
    logout = "/v1/auth/logout",
    loginWithMailToken = "/v1/auth/login/token",
    loginWithTOTPCode = "/v1/auth/login/totp",
}
export enum Endpoint {
    root = "/v1",
}

export enum EndpointUser {
    user = "/v1/users",
}

export enum EndpointProjects {
    projects = "/v1/p",
    p = "/v1/p",
}
export enum EndpointEs {
    es = "/v1/es",
}
export const MessageNotifiCation = {
    SUCCESSFULLY: "Successfully",
    ERROR: "Error",
    REGISTER_SUCCESSFULLY: "Register Successfully!",
    GET_MY_PROFILE: "Cannot Get My Profile",
};
export const DescriptionsNotification = {
    UPDATED_PROFILE: "Update Profile Template Successfully",
    UPDATED_PASSWORD: "Update PassWord Successfully",
    SET_MULTI_FACTOR: "Setting MultiFactor Successfully",
    ADD_PROJECT: "Add New Project Successfully",
    EDIT_PROJECT: "Edit Project Successfully",
    GET_MY_PROJECTS: "Get Default Project Successfully",
    CHANGE_PROJECT: "Get Project Successfully",
    DELETE_PROJECT: "Delete Project Successfully",
    CREATED_PAYMENT_METHOD: "Created Payment Successfully",
    UPDATED_PAYMENT_METHOD: "Updated Payment Successfully",
    SELECTED_STORE: "Please Select A Store",
    CREATE_PROXY: "Create Proxy Successfully",
    UPDATE_PROXY: "Update Proxy Successfully",
    DELETE_PROXY: "Delete Proxy Successfully",
};

export enum Role {
    SUDO = "SUDO",
    OWNER = "OWNER",
    ADMIN = "ADMIN",
    MEMBER = "MEMBER",
}

export const PublicRoleRoute = "**";

export enum PermissionScreens {
    DELETE_PROXY = "project-projectId:reverse-proxy:delete-proxy-path-*",
    WRITE_PROXY = "project-projectId:reverse-proxy:write-proxy-path-*",
    READ_PROXY = "project-projectId:reverse-proxy:read-proxy-path-*",

    READ_ALERT = "project-projectId:configuration:read-alert-configuration-*",
    CREATE_ALERT = "project-projectId:configuration:create-alert-configuration-*",
    UPDATE_ALERT = "project-projectId:configuration:write-alert-configuration-*",
    DELETE_ALERT = "project-projectId:configuration:delete-alert-configuration-*",

    DELETE_API_KEY = "project-projectId:api-key:delete-api-key-*",
    WRITE_API_KEY = "project-projectId:api-key:write-api-key-*",
    READ_API_KEY = "project-projectId:api-key:read-api-key-*",

    EXPORT_REPORTS_BILLING = "project-projectId:reports:export-billing-report",
    READ_REPORTS_BILLING = "project-projectId:reports:read-report-list",
    READ_REPORTS_SUMMARY = "project-projectId:reports:read-report-cost-summary",

    READ_REPORTS_TRANSACTION = "project-projectId:payments:read-transactions",
    READ_REPORTS_TRANSACTION_BY_DATE = "project-projectId:payments:read-report-transaction-by-date",
    READ_TRANSACTION = "project-projectId:payments:read-transactions",
    READ_REFUND_TRANSACTION = "project-projectId:payments:refund:read-transactions",
    CREATE_PAYMENT_PROVIDER = "project-projectId:payments:providers:create-payment-provider",
    UPDATE_PAYMENT_PROVIDER = "project-projectId:payments:providers:update-payment-provider",
    DELETE_PAYMENT_PROVIDER = "project-projectId:payments:providers:delete-payment-provider",
    READ_PAYMENT_PROVIDER = "project-projectId:payments:providers:read-payment-provider",

    WRITE_STORE = "project-projectId:store:write-store-*",
    DELETE_STORE = "project-projectId:store:delete-store-*",
    READ_STORE = "project-projectId:store:read-store-*",

    READ_INFO_PROJECT = "project-projectId:read-info",
    READ_MEMBERSHIP = "project-projectId:membership:read-members-*",
    WRITE_MEMBERSHIP = "project-projectId:membership:write-members-*",
    DELETE_MEMBERSHIP = "project-projectId:membership:delete-members-*",

    LOGS = "project-projectId:read-audit-log-*",

    ELASTICSEARCH_WRITE_INDEX = "project-projectId:elasticsearch:index-write",
    ELASTICSEARCH_READ_INDEX = "project-projectId:elasticsearch:index-read",
    ELASTICSEARCH_READ_DOCUMENT = "project-projectId:elasticsearch:document-read",
    ELASTICSEARCH_WRITE_DOCUMENT = "project-projectId:elasticsearch:document-write",

    READ_FIRM_BANKING_CONFIG = "project-projectId:firm-banking:read-firm-banking-config",
    CREATE_UPDATE_FIRM_BANKING_CONFIG = "project-projectId:firm-banking:create-update-firm-banking-config",
    READ_FIRM_BANKING_BILLINGS = "project-projectId:firm-banking:read-firm-banking-billings",
    EXPORT_FIRM_BANKING_BILLINGS = "project-projectId:firm-banking:export-firm-banking-billings",
    READ_FIRM_BANKING_TRANSACTIONS = "project-projectId:firm-banking:read-firm-banking-transaction",

    READ_RATE_LIMIT_CONFIGURATION = "p-projectId:rate-limit-configurations:read",
    READ_RATE_LIMIT_CONFIGURATION_BY_ID = "p-projectId:rate-limit-configurations:read-by-id",
    WRITE_UPDATE_RATE_LIMIT_CONFIGURATION = "p-projectId:rate-limit-configurations:create-update",
    UPDATE_RATE_LIMIT_CONFIGURATION_BY_ID = "p-projectId:rate-limit-configurations:update-by-id",
    DELETE_RATE_LIMIT_CONFIGURATION_BY_ID = "p-projectId:rate-limit-configurations:delete-by-id",

    READ_DISTRIBUTED_LOCKING_REQUEST_CONFIG = "distributed-locking-request-config:read",
    READ_DISTRIBUTED_LOCKING_REQUEST_CONFIG_BY_ID = "distributed-locking-request-config:read-by-id",
    CREATE_UPDATE_DISTRIBUTED_LOCKING_REQUEST_CONFIG = "distributed-locking-request-config:create-update",
    UPDATE_DISTRIBUTED_LOCKING_REQUEST_CONFIG_BY_ID = "distributed-locking-request-config:update-by-id",
    DELETE_DISTRIBUTED_LOCKING_REQUEST_CONFIG_BY_ID = "distributed-locking-request-config:delete-by-id",

    INVOICE_UPLOADS = 'p-projectId:e-invoice:upload',
    READ_INVOICE_UPLOADS = 'p-projectId:e-invoice:read',
    READ_INVOICE_UPLOADS_ISSUE_LOG = 'p-projectId:e-invoice:read-issue-log',
    READ_INVOICE_UPLOADS_TRACKING_LOG = 'p-projectId:e-invoice:read-tracking-log',
    READ_INVOICE_UPLOADS_STORE = 'p-projectId:e-invoice:read-store',
    CREATE_OR_UPDATE_INVOICE_UPLOADS_STORE = 'p-projectId:e-invoice:create-or-update-store',
    DELETE_INVOICE_UPLOADS_STORE = 'p-projectId:e-invoice:delete-store',
}
